
import Vue from "vue"

export default Vue.extend({
  name: "TableFilterSearch",
  props: {
    value: { type: String, required: true }
  },
  data() {
    return {
      search: ""
    }
  },
  watch: {
    search(v): void {
      this.$emit("input", v)
    }
  },
  created() {
    this.search = this.value
  }
})
