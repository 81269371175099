
import Vue from "vue"

export default Vue.extend({
  name: "ActionDialog",

  props: {
    shown: { type: Boolean, default: false },
    maxWidth: { type: Number, default: 500 },
    className: { type: String, required: false, default: "" }
  },

  data: function() {
    return {
      visible: this.shown
    }
  },

  watch: {
    visible(visible): void {
      this.$emit("update:shown", visible)
    },
    shown(visible): void {
      this.visible = visible
    }
  },

  methods: {
    close(): void {
      this.visible = false
    }
  }
})
